/*
 * @Author: OnismYY 2848335733@qq.com
 * @Date: 2022-04-11 13:14:07
 * @LastEditors: OnismYY 2848335733@qq.com
 * @LastEditTime: 2023-02-24 10:21:25
 * @FilePath: \easy-grow_system\src\plugins\vuetify.js
 * @Description: 配置vuetify主题
 */
import Vue from "vue";
import Vuetify from "vuetify/lib";
import 'typeface-roboto/index.css' 
import '@mdi/font/css/materialdesignicons.css' 
// import 'vuetify/src/styles/main.sass'
import "vuetify/src/stylus/app.styl";
import zhHans from "vuetify/lib/locale/zh-Hans";
const theme = {
  primary: "#71af65",
  secondary: "#9C27b0",
  accent: "#e91e63",
  info: "#00CAE3",
  success: "#4CAF50",
  warning: "#FB8C00",
  error: "#FF5252",
};

Vue.use(Vuetify, {
  iconfont: "md",
  theme: theme,
  //设置语言环境
  lang: {
    locales: { zhHans },
    current: "zhHans",
  },
});
