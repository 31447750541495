

export const version = 'v1.3.7'

export const mqttUrl = 'wss://broker.yigrow.cn/mqtt/'
export const mqttConf = {
  username: 'shhooserver001',
  password: 'shhoo2018',
  keepalive: 50,
  reschedulePings: true,
  clean: true,
  protocolVersion: 3,
  reconnectPeriod: 1000,
  connectTimeout: 30 * 1000
}