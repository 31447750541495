import Vue from 'vue'
import Vuex from 'vuex'
import mqtt from "mqtt"
// import * as mqtt from "mqtt"
import zlib from "zlib"


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLogin: sessionStorage.getItem('isLogin'), // 是否登录判断,登录为true，未登录为false
        token: localStorage.getItem('token'), //登录后获取的token
        loginTime: localStorage.getItem('loginTime'), //登录时间

        message: sessionStorage.getItem('message'), //登录后储存用户绑定过的基地服务器信息,可获取基地服务器别名(alias)、职级(authLevel)、Topic、sn、服务器类型
        UserAlldata: localStorage.getItem('userData'), //登录后存储用户头像//取电话号码等登录信息
        UserAllCost: localStorage.getItem('userCost'), //登录后存储用户欠费情况
        yearPayDialog: false,//年费倒计时弹框提醒

        weathersttonLastValue: null, //存储最后一笔天气数据
        mqttConnectionStatus: 0, // mqtt连接状态状态机，用于判读mqtt当前工作状态
        authLevel: null, //用户当前职级
        loding: null, //预设全局loding
        allowBack: null, //防止用户退出登录会返回到Home
        personnel: localStorage.getItem('personnel'), //登录后信息转string存、用于localhost存储(用户登录记住密码)
        personner_data: null, //用户数据存储后转换成JSON数据

        mqttClient: null, //页面设备通信MQTT客户端
        mqttBaseTopic: sessionStorage.getItem('mqttBaseTopic'), //页面设备通信保存的MQTTtopic(多基地记住密码后退出登录后保存登录时最后切换的topic)
        debugmqttClient: null, //调试终端mqtt客户端(调试工具页面单独mqtt客户端)
        debugPagemqttTopic: null, //调试页面MqttTopic

        mqttSubStatus: 0, //mqtt当前订阅状态(解决页面刚进入未订阅就发布，导致接收不到消息回复message问题)
        mqttMsg: null, //页面设备通信所有mqtt回执消息
        mqttMsgType: null, //mqtt消息回执中的confType
        mqttMsgMethod: null, //mqtt消息回执中的method
        debugmqttMsg: null, //调试页面请求后所有回执消息

        registerPhone: null, //注册成功手机号(多余操作，后期可改用router直接传参)

        promptMessage: false, // home中定义全局snackbar
        snackbarInfo: null, // home中全局snackbar提示信息
        num: 0, //snackbar连接超时计数
        time: null, //snackbar倒计时定时器
        oldTopic: sessionStorage.getItem('oldTopic'), //home页中select切换服务器对应切换的上一个topic
        qryId: null, //mqtt服务器发布携带的用户唯一标识
        baseName: null, // 存储当前服务器下的基地名称
        aliasName: null, // 存储当前服务器的名称
        warningSensor: {}, // 存储当前要设置的 报警传感器
        allCommonTag: [], // 云策略标签
        myStrategy: {}, // 运用与标准版自动化控制 要运用到云策略，现在标准版没有云策略，现在可以删除
        autoVersion: sessionStorage.getItem('autoVersion'), //存储当前基地的自动环控的版本,“1”为专业版，“0”或其他为普通版
        portNum: sessionStorage.getItem('portNum'),  //存储水肥机的端口号
    },
    getters: {
        /**对外导出mqtt回执消息
         * @param {JSON} state
         */
        weathersttonLastValue: function (state) {
            return state.weathersttonLastValue
        },
        //新消息
        mqttMsg: function (state) {
            return state.mqttMsg
        },
        debugmqttMsg: function (state) {
            return state.debugmqttMsg
        },
        mqttMsgType: function (state) {
            return state.mqttMsgType
        },
        //拿到BaseId
        mqttBaseTopic: function (state) {
            return state.mqttBaseTopic
        },
        mqttMsgMethod: function (state) {
            return state.mqttMsgMethod
        },
        //连接对象
        mqttClient: function (state) {
            return state.mqttClient
        },
        // 第1步，先判断连接状态是否等于1
        mqttConnStat: function (state) {
            return state.mqttConnectionStatus
        },
        registerPhone: function (state) {
            return state.registerPhone
        },
        warningSensor: function (state) {
            return state.warningSensor
        },
        allCommonTag: function (state) {
            return state.allCommonTag
        },
        myStrategy: function (state) {
            return state.myStrategy
        },

    },
    mutations: {
        // 基地小气候最后一笔数据
        WEATHERSTATONLASTVALUE: function (state, msg) {
            // console.log(msg);
            state.weathersttonLastValue = msg
        },
        /**
         * 1.退出登录后清除token；
         * 2.清除当前用户绑定过的基地服务器信息
         * 3.清除mqtt客户端信息
         * 4.清除用户登录个人信息
         * 5.置用户登录状态为false
         * 6.清除用户选中的BaseTopic
         * 7.remove Storage信息
         *  */
        LOGINOUT: function (state, status) {
            state.token = status
            state.loginTime = ""
            state.message = status
            state.mqttClient = status
            state.personner_data = null
            state.UserAlldata = null
            state.UserAllCost = null
            state.isLogin = false
            state.mqttBaseTopic = null
            window.sessionStorage.removeItem('mqttBaseTopic')
            window.sessionStorage.setItem('isLogin', false)
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('loginTime')
            window.localStorage.removeItem('personnel')
            window.localStorage.removeItem('userData')
            window.localStorage.removeItem('userCost')
        },
        /**
         * 1.用户登录后存储登录信息(并存与localStorage中)
         * 2.默认选择用户绑定基地服务器中第一个mqttBaseTopic
         */
        PERSONNER: function (state, data) {
            state.personnel = data
            window.localStorage.setItem('personnel', data)
            state.personner_data = JSON.parse(state.personnel)
            state.mqttBaseTopic = state.personner_data[0].mqttBaseTopic
            window.sessionStorage.setItem('mqttBaseTopic', state.mqttBaseTopic)
        },
        /**
         * 查看是否有数据(用于App中判断用户信息是否已经存在了， 没有存在再存一次)
         */
        JSPARES_PERSONNER: function (state, data) {
            state.personner_data = data
        },
        /**
         * 登录后储存用户绑定过的基地服务器信息
         */
        MESSAGE: function (state, data) {
            state.message = data
            window.sessionStorage.setItem('message', data)
        },
        /**
         *  注册成功保存手机号(多余操作，后期改用router传参)
         */
        REGISTERPHONE: function (state, data) {
            state.registerPhone = data
        },
        /**
         * 登录后单独存储token
         */
        TOKEN: function (state, data) {
            state.token = data
            state.isLogin = true
            window.localStorage.setItem('token', data)
        },

        /**存储登录时间 */

        LOGINTIME: function (state, data) {
            state.loginTime = data
            window.localStorage.setItem('loginTime', data)
        },

        /**
         * 登录后页面进行基地服务器在线情况或超时判断
         */
        showLoading: function (state) {
            if (state.personner_data && state.personner_data.length > 0) {
                state.loding = true
                // console.log(state.loding);
                state.time = setInterval(() => {
                    state.num += 1
                    if (!state.loding) {
                        clearTimeout(state.time)
                    } else if (state.num === 15) {
                        //15秒超时报错
                        state.loding = false
                        state.promptMessage = true
                        state.snackbarInfo =
                            '网络连接超时，请检查您的网络连接状态，或检查您的服务器是否正常运行!'
                        clearTimeout(state.time)
                        state.num = 0
                    }
                }, 1000)
                state.num = 0
            } else {
                state.promptMessage = true
                state.snackbarInfo =
                    '你还没有绑定过服务器，请联系管理员进行绑定后继续操作！'
            }
        },
        //全局消息回执后的snackbar提示
        showsnackbar(state, snackMsg) {
            state.promptMessage = true
            state.snackbarInfo = snackMsg
        },
        //年费状态
        setDialogSta(state, sta) {
            state.yearPayDialog = sta
        },

        /**
         * 隐藏dialog(接收消息成功后可隐藏)
         */
        hideLoading: function (state) {
            state.loding = false
            clearTimeout(state.time)
            state.num = 0
        },
        /**
         * 判断mqtt是否连接
         */
        CHANGE_MQTT_CONN_STAT: function (state, newStatus) {
            if (newStatus === 0 || newStatus === 1 || newStatus === 2) {
                state.mqttConnectionStatus = newStatus
                state.mqttSubStatus = newStatus
            } else {
                state.mqttConnectionStatus = newStatus
            }
        },
        /**
         * 用于页面头部用户切换基地服务器后改变对应的mqttTopic
         */
        SWITCH_TOPIC: function (state, data) {
            state.mqttSubStatus = 0
            state.mqttBaseTopic = data
            window.sessionStorage.setItem('mqttBaseTopic', data)
        },
        /**
         * 调试页面切换服务器取消订阅Topic
         */
        UNSUBSCRIBE_DEBUG_TOPIC: function (state, data) {
            state.debugmqttClient.unsubscribe(`${data}system/cmdack`)
        },
        /**
         * 用户切换服务器后存储上一个mqttTopic
         * @param {*} state
         * @param {string} data
         */
        OLDTOPIC: function (state, data) {
            // console.log("存储oldTopic", data);
            state.oldTopic = data
        },
        /**
         * 切换基地服务器后取消订阅上一个mqttTopic(防止消息冲突)(另一种方法可采用判断mqtt回执总的topic判断是否是本服务消息)
         * @param {*} state
         */
        SAVEUNSUBSCRIBE: function (state) {
            console.log("取消订阅-----------")
            state.mqttClient.unsubscribe(
                [
                    `${state.oldTopic}system/cmdack`,
                    `${state.oldTopic}system/otaschup`,
                    `${state.oldTopic}system/confack`,
                    `${state.oldTopic}actuator/cmdack`,
                    `${state.oldTopic}sensor/historyData`,
                    `${state.oldTopic}sensor/data`,
                    `${state.oldTopic}rotationIrrigation/cmdack`,
                    `${state.oldTopic}heating/cmdack`,
                ],
                function (err) {
                    if (err) {
                        window.console.log(err)
                        return false
                    } else if (state.mqttClient) {
                        state.mqttClient.subscribe(`${state.mqttBaseTopic}system/cmdack`)
                        state.mqttClient.subscribe(`${state.mqttBaseTopic}system/confack`)
                        // state.mqttClient.subscribe(`${state.mqttBaseTopic}system/otaschup`);
                        state.mqttClient.subscribe(`${state.mqttBaseTopic}actuator/cmdack`)
                        // state.mqttClient.subscribe(
                        //     `${state.mqttBaseTopic}sensor/historyData`
                        // );
                        // state.mqttClient.subscribe(`${state.mqttBaseTopic}sensor/data`);
                        //订阅轮灌施肥系统topic
                        // state.mqttClient.subscribe(`${state.mqttBaseTopic}rotationIrrigation/cmdack`);
                        // state.mqttClient.subscribe(`${state.mqttBaseTopic}heating/cmdack`);
                        // state.mqttSubStatus = 1;
                    }
                }
            )
        },
        /**
         * @param {*} state
         * 判断mqtt服务是否连接成功
         * >成功:订阅相应topic
         * >未成功:重新连接mqtt服务
         */
        SUBSCRIBETOPIC: function (state) {
            if (!state.mqttClient) return
            state.mqttClient.subscribe(`${state.mqttBaseTopic}system/cmdack`)
            state.mqttClient.subscribe(`${state.mqttBaseTopic}system/confack`)
            // console.log('SUBSCRIBETOPIC')
            state.mqttClient.subscribe(`${state.mqttBaseTopic}actuator/cmdack`)
            // state.mqttClient.subscribe(`${state.mqttBaseTopic}sensor/historyData`);
            // state.mqttClient.subscribe(`${state.mqttBaseTopic}sensor/data`);
            // state.mqttClient.subscribe(`${state.mqttBaseTopic}system/otaschup`);
            //05-订阅轮灌施肥系统topic
            // state.mqttClient.subscribe(`${state.mqttBaseTopic}rotationIrrigation/cmdack`);
            // state.mqttClient.subscribe(`${state.mqttBaseTopic}heating/cmdack`);
            state.mqttSubStatus = 1
        },
        SUB_TMP: function (state, topic) {
            if (!state.mqttClient) return console.log('SUB client err')
            state.mqttClient.subscribe(`${state.mqttBaseTopic}${topic}`)
            console.log('SUB: ' + topic)
            state.mqttSubStatus = 1
        },
        UNSUB_TMP: function (state, topic) {
            if (!state.mqttClient) return
            state.mqttClient.unsubscribe(`${state.mqttBaseTopic}${topic}`)
            console.log('unSUB: ' + topic)
            state.mqttSubStatus = 1
        },
        /**
         * 将mqtt服务放到state全局状态中(全局访问)
         */
        SAVE_MQTT_CLIENT: function (state, client) {
            state.mqttClient = client
        },
        /**
         * 将调试工具中的mqtt服务放到state全局状态中(全局访问)
         */
        SAVE_DEBUG_MQTT_CLIENT: function (state, client) {
            state.debugmqttClient = client
        },
        //登录获取用户微信信息(电话号码&&头像&&名称)
        WXAVATARURL: function (state, data) {
            state.UserAlldata = data
            window.localStorage.setItem('userData', data)
        },
        //登录获取用户欠费信息(欠费类别，欠费金额，截止时间时间)
        SAVE_COST: function (state, data) {
            state.UserAllCost = data
            window.localStorage.setItem('userCost', data)
        },

        /**
         * 执行器开窗设备的发布信息
         */
        PUBLISH_ACYUATOR: function (state, data) {
            if (state.mqttClient) {
                state.mqttClient.publish(`${state.mqttBaseTopic}actuator/cmd`, data)
                console.log(data, /actuator\/cmd/,)    // `${state.mqttBaseTopic}actuator/cmd`
            }
        },
        /**
         * 公司发布信息，区域信息发布
         */
        PUBLISH_COMPANY: function (state, data) {
            // console.log(data);
            // console.log(`${state.mqttBaseTopic}system/conf`, data);
            if (state.mqttClient) {
                state.mqttClient.publish(`${state.mqttBaseTopic}system/conf`, data, { qos: 1 }, function (err) {
                    // console.log('发布', 'err', err, '>>>>>>>>>>>>>>>>>>>');
                })
            }
        },

        PUBLISH_COMPANY_CMD: function (state, data) {
            if (state.mqttClient) {
                state.mqttClient.publish(`${state.mqttBaseTopic}system/cmd`, data)
                // console.log('YY公司信息的发布信息', data);
            }
        },

        // 通用控制器
        PUBLISH_COMCTRL: function (state, data) {
            if (state.mqttClient) {
                state.mqttClient.publish(`${state.mqttBaseTopic}system/cmd`, data)
            }
        },

        // 自动灌溉
        AUTO_VALVE: function (state, data) {
            if (state.mqttClient) {
                state.mqttClient.publish(`${state.mqttBaseTopic}rotationIrrigation/cmd`, data)
            }
        },
        // 自动加热
        AUTO_HEATING: function (state, data) {
            if (state.mqttClient) {
                state.mqttClient.publish(`${state.mqttBaseTopic}heating/cmd`, data)
            }
        },
        /**
         * 发布历史数据信息
         */
        PUBLISH_HISTORY_DATA: function (state, data) {
            if (state.mqttClient) {
                state.mqttClient.publish(
                    `${state.mqttBaseTopic}sensor/query/historyData`,
                    data
                )
            }
        },
        /**
         * 页面mqtt发布后获取的回执信息解析放入state中
         */
        MQTT_MSG_HANDLER: function (state, payload) {
            zlib.unzip(payload, function (err, buf) {
                if (err) payload = payload.toString()
                else payload = buf.toString()
                try {
                    payload = JSON.parse(payload)
                } catch (e) {
                    console.log('Received Message json err:', payload)
                }
                // console.log('Received Message:', payload)
                state.mqttMsgType = payload.confTyp
                state.mqttMsgMethod = payload.method
                state.mqttMsg = payload
            })
        },
        /**
         * 调试页面发布后获取到的信息
         */
        MQTT_DEBUGMSG_HANDLER: function (state, msgObj) {
            try {
                let receivedMsgObj = JSON.parse(msgObj.message)
                state.debugmqttMsg = receivedMsgObj
            } catch (error) {
                window.console.log(error)
            }
        },
        /**
         * 关闭mqtt客户端(!暂时没用到此功能)App中多人登录强制下线
         */
        END_MQTT_CLENT: function (state) {
            state.mqttClient.end()
        },
        // 存储当前服务器下的基地名称
        BASE_NAME: function (state, name) {
            state.baseName = name
        },
        // 存储当前服务器名称
        ALIASE_NAME: function (state, alise) {
            state.aliasName = alise
        },
        /**
         * 调试页面
         */
        SUBSCRIBE_TOPIC: function (state) {
            state.debugmqttClient.subscribe(
                `${state.debugPagemqttTopic}system/cmdack`
            )
        },
        SUBSCRIBE_DEVICETYPE_TOPIC: function (state) {
            state.debugmqttClient.subscribe(
                `${state.debugPagemqttTopic}system/otaschup`, {
                qos: 0,
                rh: false,
            }
            )
        },
        PUBLISH_TOPIC: function (state, data) {
            // console.log(data);
            state.debugmqttClient.publish(
                `${state.debugPagemqttTopic}system/cmd`,
                data
                // 259 aa5f6b6d177ced7748e7b9c80972f14df6d20ecf5f025504f22612255a620
            )
            // console.log("设置组ID");
        },
        QUERYDEVICETYPEINFO: function (state, data) {
            state.debugmqttClient.publish(
                `${state.debugPagemqttTopic}system/otaschdown`,
                data, {
                qos: 0,
                retained: false,
            }
            )
        },
        CHANGE_MQTT_TOPIC: function (state, changeData) {
            state.debugPagemqttTopic = changeData
        },
        PUBQUERYID: function (state, queryId) {
            state.qryId = queryId
        },
        /**
         * @param {*} state
         * @param {*} authLevel
         * 切换服务器后改变用户当前职级
         */
        CHANGEUSERAUTHLEVEL: function (state, authLevel) {
            console.log(authLevel, /authLevel/)
            state.authLevel = authLevel
        },
        // 订阅 服务器 信息
        SELECT_SERVER_DATA: function (state) {
            try {
                // state.mqttClient.subscribe('391a88e39e872f1c811d19a3c89c8715183530203b0a80041ff99d1fcaf6c8fe/cloudserver/system/apply');
                state.personner_data.forEach((item) => {
                    // &&item.authLevel === 'su'
                    if (
                        item.mqttBaseTopic === state.mqttBaseTopic
                    ) {
                        state.mqttClient.subscribe(
                            '391a88e39e872f1c811d19a3c89c8715183530203b0a80041ff99d1fcaf6c8fe/cloudserver/system/cmdack'
                            // 'b5e1726951d82efb3ab70a18e0de4d6b9a760aaed0c70a57895a758aa50279cf/cloudserver/system/cmdack'
                        )
                    }
                })
            } catch (error) { console.log(error.message) }
        },
        // 发布修改的 服务器 信息
        UPDATE_SERVER_DATA: function (state, data) {
            state.mqttClient.publish(
                '391a88e39e872f1c811d19a3c89c8715183530203b0a80041ff99d1fcaf6c8fe/cloudserver/system/cmd',
                // 'b5e1726951d82efb3ab70a18e0de4d6b9a760aaed0c70a57895a758aa50279cf/cloudserver/system/cmd',
                data
            )
            // console.log(data)
        },

        // 存储当前要设置的 报警传感器
        WARNING_SENSOR: function (state, data) {
            state.warningSensor = data
            // window.//console.log("=======>store: ", state.historyData);
        },

        // 存储云策略里所有标签
        SAVE_ALL_COMMON_TAG: function (state, data) {
            state.allCommonTag = data
        },

        //存储当前区域的策略
        SAVE_MY_STRATEGY: function (state, data) {
            state.myStrategy = data
        },
        // 存储当前基地的自动环控版本
        SAVE_AUTO_VERSION: function (state, data) {
            state.autoVersion = data
            // console.log(state.autoVersion)
            sessionStorage.setItem('autoVersion', data)
        },
        // 存储水肥机页面的端口号，然后根据相应的端口号加载页面
        SAVE_PORT_NUMBER: function (state, data) {
            state.portNum = data
            // console.log(state.portNum);
            sessionStorage.setItem('portNum', data)
        }
    },
    actions: {
        /**
         * 连接服务器并订阅
         * 1.置当前mqtt连接状态为2(未连接)
         * 2.创建mqtt连接，接收到mqtt响应connect时，改变当前mqtt连接状态为1(已连接)
         * 3.将mqtt连接放入state中用于全局访问
         * 4.Home页面进行mqtt_subscribe
         * 5.接收mqtt回执message消息存储到state总用于页面中获取数据操作
         */
        //如果连接不成功，来调用这里的连接方法connectToMqttServer
        connectToMqttServer: function ({ commit }, server) {
            commit('CHANGE_MQTT_CONN_STAT', 2)
            // var pool = [];
            // setInterval(() => {
            //     let msg = pool.shift();
            //     if (msg) {
            //         commit("MQTT_MSG_HANDLER", msg);
            //         // commit(server.msg, msg);
            //     }
            // }, 100)
            let client = mqtt.connect('wss://broker.yigrow.cn/mqtt/', {
                username: 'shhooserver001',
                password: 'shhoo2018',
                keepalive: 50,
                reschedulePings: true,
                clean: true,
                protocolVersion: 3,
                reconnectPeriod: 1000,
                connectTimeout: 30 * 1000
            })
            client.on('connect', function () {
                commit('CHANGE_MQTT_CONN_STAT', 1) //状态为1
                // commit(server.con, client); //连接服务器
                commit('SELECT_SERVER_DATA') // 订阅云端服务器发送基地服务器入网消息
                console.log(`======connect=======`)
            })
            /**
             * 解析MQTT回执消息
             */
            client.on('message', function (topic, message) {
                commit("MQTT_MSG_HANDLER", message)

                // let index2 = pool.findIndex(function (lastcmd) {
                //     return lastcmd === message;
                // });
                // if (index2 !== -1) {
                //     pool[index2] = message;
                // } else {
                //     pool.push(message);
                // }

            })
            client.on("reconnect", function () {
                // console.log(`======reconnect=======`)
                commit("CHANGE_MQTT_CONN_STAT", 2) //重连时状态改为正在连接；
            })
            client.on("close", function () {
                // console.log(`======close=======`)
                commit("CHANGE_MQTT_CONN_STAT", 0) //连接关闭时状态改为未连接；
            })
            client.on("disconnect", function () {
                console.log(`======disconnect=======`)
                commit("CHANGE_MQTT_CONN_STAT", 0) //连接断开时状态改为未连接；
            })
            client.on("offline", function () {
                console.log(`======offline=======`)
                commit("CHANGE_MQTT_CONN_STAT", 0) //掉线时状态改为未连接；
            })
            client.on("error", function (error) {
                console.log(`连接错误：${error}`)
                alert("连接错误!")
            })
            client.on("end", function () {
                console.log(`======end=======`)
                commit("CHANGE_MQTT_CONN_STAT", 0) //MQTT终止时状态改为未连接；
            })
            commit('SAVE_MQTT_CLIENT', client)

        },
        /**
         * 用于用户登录后存储token
         */
        UserLogin_TOKEN: function ({ commit }, data) {
            commit('TOKEN', data)
        },
        //用户通过登录后的登录信息转换成JSON
        UserLogin_PERSONNER: function ({ commit }, data) {
            commit('PERSONNER', data)
        },
        /**
         * 存储用户登录信息
         */
        UserLogin_MESSAGE: function ({ commit }, data) {
            commit('MESSAGE', data)
        },
        updateAppSetting: function ({ state }, allowBack) {
            state.allowBack = allowBack
        },
    },
    // plugins: [createVuexAlong()],
})