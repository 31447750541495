import store from '../store';
function mqttClientConnection() {
  return new Promise((resolve, reject) => {
    if (!store.state.mqttClient) {
      let connectData = {
        con: 'SAVE_MQTT_CLIENT',
        msg: 'MQTT_MSG_HANDLER',
      };
      let connectDebugData = {
        con: 'SAVE_DEBUG_MQTT_CLIENT',
        msg: 'MQTT_DEBUGMSG_HANDLER',
      };
      store.dispatch('connectToMqttServer', connectData);
      store.dispatch('connectToMqttServer', connectDebugData);
      resolve();
    } else {
      //console.log('客户端已连接')
    }
  });
}
export { mqttClientConnection };
