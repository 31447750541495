/*
 * @Author: OnismYY 2848335733@qq.com
 * @Date: 2022-04-11 13:14:07
 * @LastEditors: OnismYY 2848335733@qq.com
 * @LastEditTime: 2023-06-09 17:03:24
 * @FilePath: \easy-grow_system\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue"
import "./plugins/vuetify"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import axios from 'axios'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import "./assets/css/iconfont.css"

/**使用jsonp */
import { VueJsonp } from 'vue-jsonp'
import "babel-polyfill"
Vue.use(VueJsonp)

// import $ from 'jquery'
// window.jQuery = $
// window.$ = $

import VueClipboard from 'vue-clipboard2'//点击复制
Vue.use(VueClipboard)
import VueCordova from 'vue-cordova'
Vue.prototype.$publicURL = './'//vue中导入静态html页面

Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(VueCordova)

document.addEventListener('plusready', function () {
    //console.log("所有plus api都应该在此事件发生后调用，否则会出现plus is undefined。")
    plus.key.addEventListener('backbutton', function () {
        // 拦截，实现了点击物理返回键返回上一页，而不是退出app
        window.history.go(-1)
    }, false)
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")