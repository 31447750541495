import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import Login from "@/views/Login.vue";
import Auth_check from "./views/auth_check";
// import Home from "./views/Home.vue";
const Home = () => import("@/views/Home.vue");
const Payment_warn = () => import("@/views/payment_warn.vue"); //缴费提醒页面
const managementArea = () => import("@/managementArea/MangmentArea"); //区域信息
const Details = () => import("@/managementArea/Details"); //区域详细信息
const Groupreview = () => import("@/crop/Groupreview.vue");
const Groupediting = () => import("@/crop/Groupediting.vue");
const HistoricalData = () => import("@/deviceOperation/HistoricalData.vue");
const CompanyBase = () => import("@/companymanage/CompanyBase.vue");
const errorStatus = () => import("@/errorStatus/ErrorStatus.vue");
const changePassword = () => import("@/accountSetting/ChangePassword.vue");
const Mc_equipment = () => import("@/deviceOperation/Mc_equipment.vue"); //区域详情
const Regional_info = () => import("@/deviceOperation/Regional_info.vue"); //区域信息

const Ferti_center_old = () => import("@/fertigation/Ferticenter0.vue"); //旧版水肥中心
const Ferti_center = () => import("@/fertigation/Ferticenter.vue"); //新版水肥中心
const Ferti_center1 = () => import("@/fertigation/Ferticenter1.vue"); //新版水肥中心
const fog_system = () => import("@/fogSystem/FogSystem.vue"); //高压喷雾系统

const AlarmCenter = () => import("@/views/alarmN/alarmCenter.vue"); //报警中心
const server = () => import("@/device/Server.vue"); //服务器
const Sensor = () => import("@/device/Sensor.vue"); //传感器
const SensorParticularMessage = () =>
  import("@/device/SensorParticularMessage.vue"); //传感器详细信息页面
const EditSensor = () => import("@/device/EditSensor.vue"); // 编辑传感器
const SensorAlarmSet = () => import("@/device/SensorAlarmSet.vue"); // 报警设置
const Actuator = () => import("@/device/Actuator.vue"); //执行器
const EditActuator = () => import("@/device/EditActuator"); //编辑执行器
const WeathwerStation = () => import("@/device/WeatherStation.vue"); //气象站
const Roller = () => import("@/device/Roller.vue"); //开窗设备
const B16Roller = () => import("@/device/B16Roller.vue"); //开窗设备
const ComCtrl = () => import("@/device/ComCtrl.vue"); //通用控制器
const Screen = () => import("@/device/Screen.vue"); //拉幕
const Curtain = () => import("@/device/Curtain.vue"); //通用控制器
const CameraMain = () => import("@/device/camera/CameraMain.vue"); //摄像头
const Automation = () =>
  import("@/automaticSettings/automaticSettingsMain.vue"); // 自动化设置（策略管理）
const EnvironmentControlSettings = () =>
  import("@/automaticSettings/environmentControlSettings.vue"); // 环境控制设置
const AutomaticEnvironmentControlMain = () =>
  import("@/automaticSettings/AutomaticEnvironmentControlMain.vue"); // 新环境控制设置
const RollOfFilmControl = () =>
  import("@/automaticSettings/RollOfFilmControl.vue"); // 卷膜控制 页面
const PullACurtainControl = () =>
  import("@/automaticSettings/PullACurtainControl.vue"); // 卷膜控制 页面
const EditCustomStrategy = () =>
  import("@/automaticSettings/EditCustomStrategy.vue"); // 编辑 卷膜控制 策略编辑 页面
const EditPullACurtainControl = () =>
  import("@/automaticSettings/EditPullACurtainControl.vue"); // 编辑 拉幕控制 页面
const FanControl = () => import("@/automaticSettings/FanControl.vue"); // 风机控制 页面
const FanControlSetting = () =>
  import("@/automaticSettings/FanControlSetting.vue"); // 风机控制 编辑 页面

const AllWindSpeedControl = () =>
  import("@/automaticSettings/AllWindSpeedControl.vue"); // 风速全控 页面
const WindSpeedControlSetting = () =>
  import("@/automaticSettings/WindSpeedControlSetting.vue"); // 风速编辑 页面

const StrategySetting = () => import("@/automaticSettings/StrategySetting.vue");
const ForecastWeather = () => import("@/automaticSettings/forecastWeather.vue"); //降雨雪霜自动关棚
const AutomaticWaterControl = () =>
  import("@/automaticSettings/automaticWaterControl.vue"); //自动水控制
const Fertilizer = () => import("@/automaticSettings/AutoFertilizer.vue"); //自动水肥机

const BaseLive = () => import("@/device/Camera.vue"); //摄像头
const GroupPolicy = () => import("@/strategy/GroupPolicy.vue"); //策略组
const DataSource = () => import("@/strategy/DataSource.vue"); //数据源
const StrInformation = () => import("@/strategy/StrInformation.vue"); //某个策略组下的所有详细信息
const OutputInfo = () => import("@/strategy/OutputInfo.vue"); //输出信息
const Retrieve_Password = () => import("@/views/Retrieve_password.vue");
const Register_Success = () => import("@/components/Register_Success.vue");
const LogControll = () => import("@/logControl/LogControll.vue"); // 卷膜操作日志
const PullControllLog = () => import("@/logControl/PullControllLog.vue"); // 拉幕操作日志
const AutoVentilationLog = () => import("@/logControl/AutoVentilationLog.vue"); // 拉幕操作日志
const AutomaticEnvironmentLogMain = () =>
  import("@/logControl/AutomaticEnvironmentLogMain.vue"); // 自动环境控制日志记录
const valveEquipment = () =>
  import(
    "@/deviceOperation/deviceInformation/valveEquipment/valveEquipment.vue"
  );

const SensorWarningSet = () => import("@/warningSetUp/SensorWarningSet.vue");
const IrrigateWarningSet = () =>
  import("@/warningSetUp/IrrigateWarningSet.vue");

/**综合控制器 */
const BackEndView = () => import("./backEndController/BackEndView.vue");
const BackTabs = () => import("@/backEndController/backEndDevice/BackTabs.vue");
const BackEndVentilation = () =>
  import("@/backEndController/backEndDevice/BackEndVentilation.vue");
const BackEndCurtain = () =>
  import("@/backEndController/backEndDevice/BackEndCurtain.vue");
const BackEndFan = () =>
  import("@/backEndController/backEndDevice/BackEndFan.vue");
const BackEndSwitch = () =>
  import("@/backEndController/backEndDevice/BackEndSwitch.vue");
const BackEndIllumination = () =>
  import("@/backEndController/backEndDevice/BackEndIllumination.vue");
const NetworkDevice = () =>
  import("@/backEndController/network/NetworkDevice.vue");
Vue.use(Router);
const router = new Router({
  // mode: 'history',
  base: "/",
  routes: [
    // { path: '/', redirect: '/home' },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        allowBack: false,
      },
    },
    {
      path: "/auth_check",
      name: "auth_check",
      component: Auth_check,
    },
    {
      path: "/retrieve_password",
      name: "retrieve_password",
      component: Retrieve_Password,
    },
    {
      path: "/Register_Success",
      name: "Register_Success",
      component: Register_Success,
    },
    {
      path: "/",
      // name: "home",
      component: Home,
      beforeEnter: (to, from, next) => {
        // ...
        // console.log(to);
        // console.log(from)
        let storageData = JSON.parse(window.localStorage.getItem("UserInfo"));
        // console.log(storageData)
        let login = window.sessionStorage.getItem("isLogin");
        // console.log(login)
        // to.path == '/' &&
        if (storageData && storageData.remenberPsd > +new Date()) {
          next();
        } else if (to.path == "/" && login == "true") {
          next();
        } else if (login == "true") {
          next();
        } else {
          next({
            path: "/login",
          });
        }
      },
      children: [
        //---------------------------------主页---------------------------------//
        //实时数据
        {
          path: "/",
          // name: 'info',
          component: Regional_info,
        },
        //---------------------------------缴费提醒页面---------------------------------//
        {
          path: "/payment_warn",
          // name: 'info',
          component: Payment_warn,
        },
        //------------------------------管理区域------------------------------//
        //管理区域
        {
          path: "/managementArea/managementArea",
          name: "managementArea",
          component: managementArea,
        },
        {
          path: "/managementArea/Details",
          name: "Details",
          component: Details,
        },
        //------------------------------作物管理------------------------------//
        //作物信息--分组预览
        {
          path: "/crop/Groupreview",
          name: "Groupreview",
          component: Groupreview,
        },
        //种植季信息--分组编辑
        {
          path: "/crop/Groupediting",
          name: "Groupediting",
          component: Groupediting,
        },
        //------------------------------设备管理------------------------------//
        //服务器
        {
          path: "/device/server",
          name: "server",
          component: server,
        },
        //传感器
        {
          path: "/device/sensor",
          name: "sensor",
          component: Sensor,
        },
        //传感器详细信息页面
        {
          path: "/device/SensorParticularMessage",
          name: "SensorParticularMessage",
          component: SensorParticularMessage,
        },
        // 编辑传感器页面
        {
          path: "/device/EditSensor",
          name: "EditSensor",
          component: EditSensor,
        },
        // 报警设置页面
        {
          path: "/device/SensorAlarmSet",
          name: "SensorAlarmSet",
          component: SensorAlarmSet,
        },
        //执行器
        {
          path: "/device/actuator",
          name: "actuator",
          component: Actuator,
        },
        //编辑执行器
        {
          path: "/device/editactuator",
          name: "editactuator",
          component: EditActuator,
        },
        // 开窗设备
        {
          path: "/device/Roller",
          name: "Roller",
          component: Roller,
        },
        // 开窗设备
        {
          path: "/device/B16Roller",
          name: "B16Roller",
          component: B16Roller,
        },
        // 通用控制器
        {
          path: "/device/ComCtrl",
          name: "ComCtrl",
          component: ComCtrl,
        },
        // 拉幕
        {
          path: "/device/Screen",
          name: "Screen",
          component: Screen,
        },
        // 卷被
        {
          path: "/device/Curtain",
          name: "Curtain",
          component: Curtain,
        },
        // 气象站
        {
          path: "/device/WeatherStation",
          name: "WeathwerStation",
          component: WeathwerStation,
        },
        //基地实况
        {
          path: "/device/Camera",
          name: "Camera",
          component: BaseLive,
        },
        //摄像头
        {
          path: "/device/camera/CameraMain",
          name: "CameraMain",
          component: CameraMain,
        },
        //自动化设置（策略管理）
        {
          path: "/automaticSettings/automaticSettingsMain",
          name: "Automation",
          component: Automation,
        },
        // 降雨雪霜自动关棚
        {
          path: "/automaticSettings/forecastWeather",
          name: "ForecastWeather",
          component: ForecastWeather,
        },
        {
          path: "/automaticSettings/automaticWaterControl",
          name: "AutomaticWaterControl",
          component: AutomaticWaterControl,
        },
        {
          path: "/automaticSettings/AutoFertilizer",
          name: "Fertilizer",
          component: Fertilizer,
        },
        {
          path: "/warningSetUp/SensorWarningSet",
          name: "SensorWarningSet",
          component: SensorWarningSet,
        },
        {
          path: "/warningSetUp/IrrigateWarningSet",
          name: "IrrigateWarningSet",
          component: IrrigateWarningSet,
        },
        // 环境控制设置
        {
          path: "/automaticSettings/environmentControlSettings",
          name: "EnvironmentControlSettings",
          component: EnvironmentControlSettings,
        },
        // 新自动环境控制设置 AutomaticEnvironmentControlMain
        {
          path: "/automaticSettings/AutomaticEnvironmentControlMain",
          name: "AutomaticEnvironmentControlMain",
          component: AutomaticEnvironmentControlMain,
        },
        // 全控风速页面 AllWindSpeedControl
        {
          path: "/automaticSettings/AllWindSpeedControl",
          name: "AllWindSpeedControl",
          component: AllWindSpeedControl,
        },
        // 风速设置页面 WindSpeedControlSetting
        {
          path: "/automaticSettings/WindSpeedControlSetting",
          name: "WindSpeedControlSetting",
          component: WindSpeedControlSetting,
        },
        // 卷膜控制 页面 RollOfFilmControl
        {
          path: "/automaticSettings/RollOfFilmControl",
          name: "RollOfFilmControl",
          component: RollOfFilmControl,
        },
        // 拉幕控制 页面 PullACurtainControl
        {
          path: "/automaticSettings/PullACurtainControl",
          name: "PullACurtainControl",
          component: PullACurtainControl,
        },
        // 自定义策略 编辑页面
        {
          path: "/automaticSettings/EditCustomStrategy",
          name: "EditCustomStrategy",
          component: EditCustomStrategy,
        },
        // 拉幕电机 编辑页面
        {
          path: "/automaticSettings/EditPullACurtainControl",
          name: "EditPullACurtainControl",
          component: EditPullACurtainControl,
        },
        // 风机 控制页面
        {
          path: "/automaticSettings/FanControl",
          name: "FanControl",
          component: FanControl,
        },
        // 风机 控制页面
        {
          path: "/automaticSettings/FanControlSetting",
          name: "FanControlSetting",
          component: FanControlSetting,
        },
        // 云策略
        {
          path: "/automaticSettings/StrategySetting",
          name: "StrategySetting",
          component: StrategySetting,
        },

        //综合控制器
        {
          path: "/backEndController/BackEndView",
          name: "BackEndView",
          component: BackEndView,
          redirect: "/backEndDevice/BackTabs",
          children: [
            //tab菜单列
            {
              path: "/backEndDevice/BackTabs",
              name: "BackTabs",
              component: BackTabs,
              redirect: "/backEndDevice/BackEndVentilation",
              children: [
                //通风
                {
                  path: "/backEndDevice/BackEndVentilation",
                  name: "BackEndVentilation",
                  component: BackEndVentilation,
                },
                //拉幕
                {
                  path: "/backEndDevice/BackEndCurtain",
                  name: "BackEndCurtain",
                  component: BackEndCurtain,
                },
                //风机
                {
                  path: "/backEndDevice/BackEndFan",
                  name: "BackEndFan",
                  component: BackEndFan,
                },
                //开关
                {
                  path: "/backEndDevice/BackEndSwitch",
                  name: "BackEndSwitch",
                  component: BackEndSwitch,
                },
                //照明
                {
                  path: "/backEndDevice/BackEndIllumination",
                  name: "BackEndIllumination",
                  component: BackEndIllumination,
                },
              ],
            },

            /**综合控制器-所有已入网设备 */
            {
              path: "/network/NetworkDevice",
              name: "NetworkDevice",
              component: NetworkDevice,
            },
          ],
        },

        //-------------------------------管理策略-----------------------------//
        //策略组
        {
          path: "/strategy/groupPolicy",
          name: "groupPolicy",
          component: GroupPolicy,
        },
        //数据源
        {
          path: "/strategy/dataSource",
          name: "dataSource",
          component: DataSource,
        },
        //策略组详细信息
        {
          path: "/strategy/StrInformation",
          name: "StrategyInformation",
          component: StrInformation,
        },
        //输出信息
        {
          path: "/strategy/OutputInfo",
          name: "OutputInfo",
          component: OutputInfo,
        },
        //-------------------------------数据预览-----------------------------//
        //区域信息详细信息
        {
          path: "/devicedata/Mc_equipment",
          name: "Mc_equipment",
          component: Mc_equipment,
        },
        //区域信息
        {
          path: "/devicedata/Regional_info",
          // name: "Regional_info",
          component: Regional_info,
        },
        //旧版水肥中心
        {
          path: "/ferticenter_old",
          component: Ferti_center_old,
          // beforeEnter(to, from, next) {
          //     window.open("https://yigrow.cn/ferticenter/test.txt", '_blank');
          // }
        },
        //新版水肥中心
        {
          path: "/ferticenter",
          component: Ferti_center,
          // beforeEnter(to, from, next) {
          //     window.open("https://yigrow.cn/ferticenter/test.txt", '_blank');
          // }
        },
        {
          path: "/ferticenter1",
          component: Ferti_center1,
          // beforeEnter(to, from, next) {
          //     window.open("https://yigrow.cn/ferticenter/test.txt", '_blank');
          // }
        },
        {
          path: "/fog_system",
          component: fog_system,
          // beforeEnter(to, from, next) {
          //     window.open("https://yigrow.cn/ferticenter/test.txt", '_blank');
          // }
        },
        //报警中心
        {
          path: "/alarmCenter",
          component: AlarmCenter,
        },
        //历史数据
        {
          path: "/devicedata/historicalData",
          name: "HistoricalData",
          component: HistoricalData,
        },
        // 基地管理 (基地信息管理)
        {
          path: "/companymanage/CompanyBase",
          name: "CompanyBase",
          component: CompanyBase,
        },
        //-------------------------------控制日志-----------------------------//
        // 卷膜操作日志
        {
          path: "/logControll/LogControll",
          name: "LogControll",
          component: LogControll,
        },
        // 拉幕操作日志
        {
          path: "/logControll/PullControllLog",
          name: "PullControllLog",
          component: PullControllLog,
        },
        // 自动通风控制日志
        {
          path: "/logControll/AutoVentilationLog",
          name: "AutoVentilationLog",
          component: AutoVentilationLog,
        },
        // 自动环境控制记录
        {
          path: "/logControll/AutomaticEnvironmentLogMain",
          name: "AutomaticEnvironmentLogMain",
          component: AutomaticEnvironmentLogMain,
        },
        //-------------------------------报警状态-----------------------------//
        {
          path: "/errorstatus/errorstatus",
          name: "errorstatus",
          component: errorStatus,
        },
        //-------------------------------修改密码-----------------------------//
        {
          path: "/accountSetting/changePassword",
          name: "changePassword",
          component: changePassword,
        },
        {
          path: "/devicedata/Mc_equipment/valveEquipment",
          name: "valveEquipment",
          component: valveEquipment,
        },
      ],
    },
  ],
  /**
   * 每次页面刷新都默认显示到顶部
   */
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

// 计算两个日期所相差的天数
function getDifferTime(startDate, endDate) {
  if (startDate !== null && startDate !== undefined && startDate !== "") {
    let startTime = new Date(
      Date.parse(startDate.replace(/-/g, "/"))
    ).getTime();
    let endTime = new Date(Date.parse(endDate.replace(/-/g, "/"))).getTime();
    // let dates = Math.abs((startTime - endTime)) / (1000 * 60 * 60 * 24);
    let dates = (startTime - endTime) / (1000 * 60 * 60 * 24); //需要负数
    return dates;
  } else {
    // console.log("输入的时间格式有误")
  }
}
/**
 * 全局导航守卫，避免用户退出登录后点击后退还能回到HOME
 */
router.beforeEach((to, from, next) => {
  // to要跳转到的路径
  // from从哪个路径来
  // next往下执行的回调
  next();
  let allowBack = true; //    给个默认值true
  if (to.meta.allowBack !== undefined) {
    allowBack = to.meta.allowBack;
  }
  if (!allowBack) {
    history.pushState(null, null, location.href);
  }

  store.dispatch("updateAppSetting", allowBack);
  // console.log(to, from)
  // 防止在home页点击一次退出到登录页

  // if (to.path && to.path == "/login" && from.path == "/") {
  //     // alert("home")
  //     if (new Date().getTime() - first < 2000) {
  //         navigator.app.exitApp();
  //     } else {
  //         store.commit('showsnackbar', "再按一次退出应用")
  //         first = new Date().getTime();
  //         return false;
  //     }
  // }
  // next();

  //判断，如果没有存在欠费情况，正常跳转路由，如果存在欠费情况，跳转至缴费提醒页面
  let newUserAllCost = JSON.parse(store.state.UserAllCost);
  // console.log(newUserAllCost)
  if (
    newUserAllCost !== null &&
    newUserAllCost !== undefined &&
    newUserAllCost !== ""
  ) {
    if (
      newUserAllCost.cost !== null &&
      newUserAllCost.cost !== "" &&
      newUserAllCost.cost !== undefined
    ) {
      let a = Number(newUserAllCost.cost.yearlyPayment.total); //存储的年费金额
      let b = Number(newUserAllCost.cost.constructCost.arrearsAmount); //存储的工程费金额
      // console.log(a, b);
      if (a === 0 && b === 0) {
        //如果没有欠费(年费/工程费)，直接跳转
        next();
      } else {
        /**************************计算时间开始************************ */
        //计算时间，如果计算出来的相差天数为负，跳转缴费页面，计算相差天数7天内，弹框提示
        //年费到期时间减去今日时间
        let newDate = new Date().toISOString().substr(0, 10); //当前日期

        //年费到期时间减去今日日期
        var timerYear = getDifferTime(
          newUserAllCost.cost.yearlyPayment.endDate,
          newDate
        );

        //工程费到期时间减去今日时间
        var timerPro = getDifferTime(
          newUserAllCost.cost.constructCost.lastPaymentDate,
          newDate
        );

        // console.log(timerYear, timerPro);
        if (timerYear < 0 || timerPro < 0) {
          //跳转到催费页面，不影响切换基地
          if (to.path == "/payment_warn") {
            next();
          } else {
            next({
              path: "/payment_warn", //跳转到缴费提醒页面
              // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
              query: {
                redirect: to.fullPath,
              },
            });
          }
        } else {
          next();
          // if (timerYear <= 7) {
          //     //相差小余7天，弹框提醒,正常跳转页面
          //     store.commit("setDialogSta", true);
          //     next();
          // } else {
          //     //相差超过7天，正常跳转
          //     next();
          // }
        }
        /**************************计算时间结束************************ */

        // if (to.path == '/payment_warn') {
        //     next()
        // }
        // else {
        //     next({
        //         path: '/payment_warn',//跳转到缴费提醒页面
        //         // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
        //         query: {
        //             redirect: to.fullPath
        //         }
        //     })
        // }
      }
    } else {
      // console.log("cost为空")
      next();
    }
  } else {
    // console.log("newUserAllCost为空")
    next();
  }
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => console.log(err));
};

export default router;
