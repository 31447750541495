import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[_c('div',{staticClass:"text-xs-center"},[_c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v("提示")]),_c(VCardText,[_vm._v(_vm._s(_vm.msg))]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","flat":""},on:{"click":function($event){;(_vm.dialog = false), _vm.loginOut()}}},[_vm._v("确定")])],1)],1)],1),_c(VSnackbar,{staticStyle:{"position":"fixed","top":"20px"},attrs:{"top":"","timeout":3000},model:{value:(_vm.promptMessage),callback:function ($$v) {_vm.promptMessage=$$v},expression:"promptMessage"}},[_vm._v(" "+_vm._s(_vm.snackbarInfo)+" "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.promptMessage = false}}},[_c(VIcon,[_vm._v("mdi-close-circle")])],1)],1)],1),(_vm.isRouterAlive)?_c('router-view'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }